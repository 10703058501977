import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 0
  },
  content: {
    backgroundColor: "#fff",
    minHeight: "100%",
    height: '100%'
  }
}));

const Minimal = props => {
  const { children, auth } = props;

  const classes = useStyles();

  React.useEffect(() => {
    if(auth.isLogged) {
      props.history.push('/dashboard');
    }
  });

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(withRouter(Minimal));
