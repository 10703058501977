import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { SchoolCreationRequestSearchInput as SearchInput, SchoolCreationRequestsTable } from "./components";
import { ThemeType } from "../../theme/types";
import { useTitle } from "../../hooks";
import { SchoolCreationRequest} from "../../models/SchoolCreationRequest";
import { ConnectedProps, connect } from "react-redux";
import { flashMessage } from "../../store/modules/alerts/actions";
import { db } from "../../services/firebase/init";

const useStyles = makeStyles((theme: ThemeType) => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const mapDispatchToProps = { flashMessage };
const connector = connect(null, mapDispatchToProps);

type ComponentProps = ConnectedProps<typeof connector>;

const SchoolCreationRequestsList : React.FC<ComponentProps> = (props) => {
    const classes = useStyles();
    const [schoolCreationRequests, setSchoolCreationRequests] = useState<SchoolCreationRequest[]>([]);
    const [filteredRequests, setFilteredRequests] = useState<SchoolCreationRequest[]>([]);

    useTitle("Demandes de création d'écoles");

    useEffect(() => {
        const unsubscribe = db.collection('schoolCreationRequests')
            .orderBy('createdAt', 'desc')
            .onSnapshot(((querySnapshot) => {
                const requests = querySnapshot.docs.map((document) => {
                    return { id: document.id, ...document.data() } as SchoolCreationRequest
                });

                setSchoolCreationRequests(requests);
                setFilteredRequests(requests);
            }));

        return () => unsubscribe();
    }, []);


    function filterRequests(inputValue : string) {
        if(typeof inputValue === 'string') {
            const requests = schoolCreationRequests.filter((request: SchoolCreationRequest) => {
                return request.name.indexOf(inputValue) > -1;
            });

            setFilteredRequests(requests);
        }
    }

    return (
        <div className={classes.root}>
            <SearchInput onChange={filterRequests}/>
            <div className={classes.content}>
                <SchoolCreationRequestsTable data={filteredRequests} />
            </div>
        </div>
    );
};

export default SchoolCreationRequestsList;