import { makeStyles } from "@material-ui/styles";
import { ThemeType } from "../../theme/types";

const useButtonStyles = makeStyles((theme) => ({
    actionButton: {
        borderRadius: "50px",
        textTransform: "capitalize"
    }
}));

export default useButtonStyles;
