import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/styles";
import { ThemeType } from "../../../../theme/types";
import { flashMessage } from "../../../../store/modules/alerts/actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { Button, Card, CardContent, CircularProgress, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { Auth, AuthenticableInterface } from "../../../../models/Auth";
import { School, SchoolInterface } from "../../../../models/School";
import { AppState } from "../../../../store/rootReducer";
import {Profile, SCHOOL_PROFILE_NAME_TYPE} from "../../../../models/Profile";
import { User } from "../../../../models/User";
import { db, firebaseInstance as firebase } from "../../../../services/firebase/init";
import {
    ACCEPTED_STATUS,
    PENDING_STATUS, REJECTED_STATUS,
    SchoolCreationRequest,
    SchoolCreationRequestStatus,
    STATUS_MAPPER,
    updateSchoolCreationRequest
} from "../../../../models/SchoolCreationRequest";

const useStyles =  makeStyles((theme: ThemeType) => ({
    formControl: {
        marginTop: "20px",
        width: "100%"
    },
    loader: {
        color: 'white'
    },
    inputLabel: {
        backgroundColor: "white",
        paddingLeft: "6px",
        paddingRight: "6px"
    }
}));

const mapDispatchToProps = { flashMessage };
const mapStateToProps = (state: AppState) => ({ auth : state.auth });
const connector = connect(mapStateToProps, mapDispatchToProps);

interface InitialValues {
    status: SchoolCreationRequestStatus,
    comments: string | null
}
type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = ReduxProps & RouteComponentProps & { request: SchoolCreationRequest };

const SchoolCreationRequestValidationForm : React.FC<OwnProps> = (props) => {
    const { request, auth } = props;
    const classes = useStyles();
    const requestDefaultStatus = [PENDING_STATUS, ACCEPTED_STATUS , REJECTED_STATUS];
    const initialValues : InitialValues = { status: request.status, comments: request.comments as string };
    const [loading, setLoading] = useState<boolean>(false);
    const [authToken, setAuthToken] = useState<string>('');

    useEffect(() => {
        retrieveToken();
    }, []);

    async function retrieveToken() {
        if(auth.user) {
            try {
                const token : string = await auth.user.getIdToken();
                setAuthToken(token);
            } catch(error) {
               console.log("Can not get token", error);
            }
        }
    }

    function handleSubmit(values: InitialValues) {
        setLoading(true);

        if(values.comments === undefined) {
            values.comments = "";
        }

        updateSchoolCreationRequest(request, values)
            .then(() => _handleSchoolCreationProcess(values))
            .then(_flashMessage)
            .then(_redirect)
            .catch(() => setLoading(false));
    }

    async function _handleSchoolCreationProcess(values: InitialValues) {
        if(values.status !== ACCEPTED_STATUS) {
            return null;
        }

        const { name, address, supervisorName, supervisorEmail } = request;
        const newSchoolAttribute : SchoolInterface = {
            chairmanPhones: [],
            city: request.city,
            name,
            address,
            supervisorName,
            supervisorEmail,
            phoneNumber: [request.phoneNumber],
            email: [request.email as string],
            studentSMSCount: 0, schoolSMSCount: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            schoolCode: request.schoolCode,
            token: null
        };

        try {
            const snapshot = await School.create(newSchoolAttribute);
            
            await _handleSupervisorManagementProcess({schoolId: snapshot.id});

        } catch (error) {
            console.log("Can not create a school")
        }
    }

    async function _handleSupervisorManagementProcess(defaultAttribute: { schoolId: string}) {
        const { schoolId } = defaultAttribute;

        if(request.hasSchool) {
            return _appendSupervisorSchools({ supervisorEmail: request.supervisorEmail, schoolId });
        }

        return  _createSupervisor({ schoolId });
    }

    async function _appendSupervisorSchools(attributes: { supervisorEmail: string, schoolId: string}) {
        const { supervisorEmail, schoolId } = attributes;

        try {
            const querySnapshot = await User.fetchBy({ key: "email", value: supervisorEmail });

            const supervisorId = querySnapshot.docs[0].id;

            db.collection('users')
                .doc(supervisorId)
                .update({
                    schoolIds: firebase.firestore.FieldValue.arrayUnion(schoolId)
                });

        } catch(error) {
            console.log("Can not fetch a user", error);
        }
    }

    async function _createSupervisor(attributes : { schoolId: string }) {
        try {
            const querySnapshot = await Profile.getSupervisor();
            const supervisorProfile = querySnapshot.docs[0];

            const authAttributes : AuthenticableInterface = {
                email: request.supervisorEmail, password: "123456789",
                displayName: request.supervisorName, schoolIds: [attributes.schoolId],
                profileId: supervisorProfile.id, type: SCHOOL_PROFILE_NAME_TYPE
            };

            return Auth.create(authAttributes, { Authorization: `Bearer ${authToken}` })
                        .catch(error => console.log(error));

        } catch(error) {
            console.log("Can not create a supervisor", error);
        }
    }

    function _flashMessage() {
        props.flashMessage({
            shortMessage:"L'action a été effectuée avec succès",
            level: 'success',
            uuid: ''
        });
    }

    function _redirect() {
        props.history.push("/school-creation-requests");
    }

    function renderButtonText() {
        if(loading) {
            return <CircularProgress  className={classes.loader} size={25} />
        }

        return "Valider";
    }

    function formIsDisabled() : boolean {
        if(request.status !== PENDING_STATUS) {
            return true;
        }

        return loading;
    }

    return (
        <div>
            <Card>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <CardContent>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="status" className={classes.inputLabel}>Statut</InputLabel>
                                    <Field
                                        component={Select}
                                        name="status"
                                        inputProps={{
                                            id: 'status',
                                        }}
                                        disabled={formIsDisabled()}
                                    >
                                        {
                                            requestDefaultStatus.map((status: string, id: number) => (
                                                <MenuItem value={status} key={id}>{STATUS_MAPPER[status]}</MenuItem>)
                                            )
                                        }
                                    </Field>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Commentaires"
                                        name="comments"
                                        variant="outlined"
                                        multiline={true}
                                        rows={10}
                                        value={request.comments}
                                        disabled={formIsDisabled()}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <Button variant="contained" color="secondary" type="submit" disableElevation
                                        disabled={formIsDisabled()}
                                    >
                                        { renderButtonText() }
                                    </Button>
                                </FormControl>
                            </CardContent>
                        </form>
                    )}
                </Formik>
            </Card>
        </div>
    )
};

export default connector(withRouter(SchoolCreationRequestValidationForm));

