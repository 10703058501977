import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from './components';
import {Main as MainLayout, Minimal as MinimalLayout} from './layouts';

import {
    Dashboard as DashboardView,
    ProductList as ProductListView,
    UserList as UserListView,
    Typography as TypographyView,
    Icons as IconsView,
    Account as AccountView,
    Settings as SettingsView,
    SignUp as SignUpView,
    SignIn as SignInView,
    NotFound as NotFoundView,
    SchoolCreationRequestsList as SchoolCreationRequestsView,
    SingleSchoolCreationRequest as SingleSchoolCreationView,
    SchoolList as SchoolListView,
    SingleSchool as SingleSchoolView,
} from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/dashboard"
            />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <RouteWithLayout
                component={UserListView}
                exact
                layout={MainLayout}
                path="/users"
            />
            <RouteWithLayout
                component={SchoolCreationRequestsView}
                exact
                layout={MainLayout}
                path="/school-creation-requests"
            />
            <RouteWithLayout
                component={SingleSchoolCreationView}
                exact
                layout={MainLayout}
                path="/school-creation-requests/:id"
            />
            <RouteWithLayout
                component={SchoolListView}
                exact
                layout={MainLayout}
                path="/schools"
            />
            <RouteWithLayout
                component={SingleSchoolView}
                exact
                layout={MainLayout}
                path="/schools/:id"
            />
            <RouteWithLayout
                component={ProductListView}
                exact
                layout={MainLayout}
                path="/products"
            />
            <RouteWithLayout
                component={TypographyView}
                exact
                layout={MainLayout}
                path="/typography"
            />
            <RouteWithLayout
                component={IconsView}
                exact
                layout={MainLayout}
                path="/icons"
            />
            <RouteWithLayout
                component={AccountView}
                exact
                layout={MainLayout}
                path="/account"
            />
            <RouteWithLayout
                component={SettingsView}
                exact
                layout={MainLayout}
                path="/settings"
            />
            <RouteWithLayout
                component={SignUpView}
                exact
                layout={MinimalLayout}
                path="/sign-up"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <Redirect to="/not-found"/>
        </Switch>
    );
};

export default Routes;
