import React from "react";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { SchoolInterface } from "../../models/School";
import { makeStyles } from "@material-ui/core/styles";
import Backlink from "../../components/Backlink";
import {Grid} from "@material-ui/core";
import {SchoolDetailsCard} from "./components/SchoolCards";

type QueryParams = {};
type LocationStateParams = { school : SchoolInterface };
type OwnProps = {} & RouteComponentProps<QueryParams, StaticContext, LocationStateParams>;

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const SingleSchool : React.FC<OwnProps> = (props) => {
    const classes = useStyle();
    const { school } = props.location.state;

    return (
        <div className={classes.root}>
            <Backlink to="/schools"/>
            <div className={classes.content}>
                <Grid container spacing={2}  >
                    <Grid item xs={12}>
                        <SchoolDetailsCard school={school}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default SingleSchool;