import React from "react";
import {
    DoneAll as DoneAllIcon,
    HighlightOff as HighlightOffIcon,
    Timelapse as TimelapseIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import {
    ACCEPTED_STATUS,
    REJECTED_STATUS,
    SchoolCreationRequest,
    STATUS_MAPPER
} from "../../../../models/SchoolCreationRequest";

type OwnProps = {
    request: SchoolCreationRequest
}

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: "#e5fce5"
    },
    error: {
        backgroundColor: "#fce3e3"
    },
    pending: {
        backgroundColor: "#ebf6fe"
    }
}));


const StatusChip : React.FC<OwnProps> = (props) => {

    const { status } = props.request;
    const classes = useStyles();

    let icon = (<TimelapseIcon/>);
    let color = classes.pending;

    if(status === REJECTED_STATUS) {
        icon = (<HighlightOffIcon/>);
        color = classes.error
    }

    if(status === ACCEPTED_STATUS) {
        icon = (<DoneAllIcon/>);
        color = classes.success
    }

    return <Chip label={STATUS_MAPPER[status]} icon={icon} className={color}/>
}

export default StatusChip;