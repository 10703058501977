import { BaseModel } from "./BaseModel";
import { db } from "../services/firebase/init";

export const PENDING_STATUS : string = "pending";
export const ACCEPTED_STATUS = "accepted";
export const REJECTED_STATUS = "rejected";

export const STATUS_MAPPER = {
    [PENDING_STATUS as string]: "En attente",
    [ACCEPTED_STATUS as string]: "Validée",
    [REJECTED_STATUS as string]: "Rejetée"
};

export type SchoolCreationRequestStatus =  typeof PENDING_STATUS | typeof ACCEPTED_STATUS | typeof REJECTED_STATUS;
export const SchoolCreationRequestsCollection = "schoolCreationRequests";

export interface SchoolCreationRequest extends BaseModel {
    id?: string
    name: string
    phoneNumber: string
    address? : string
    email? : string
    supervisorName : string
    supervisorEmail: string
    authorizationRegistrationNumber: string,
    status: SchoolCreationRequestStatus,
    comments? : string
    city:string
    hasSchool? : string,
    schoolCode: string
}

export function getSchoolCreationRequests() {
    return db.collection(SchoolCreationRequestsCollection)
            .get();
}

export function getSchoolCreationRequest(id: string) {
    return db.collection(SchoolCreationRequestsCollection)
            .doc(id)
            .get();
}

export function updateSchoolCreationRequest(
    request: SchoolCreationRequest,
    attributes: { status: SchoolCreationRequestStatus, comments: string | null }
){
    return db.collection(SchoolCreationRequestsCollection)
        .doc(request.id)
        .set({...request, ...attributes});
}

