import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_STATE_UPDATED,
  AuthState,
  AuthActionTypes, AUTH_LOADING,
} from './types'
import { updateObject } from '../../../helpers/functions';

const initialState: AuthState = {
  user: null,
  isLogged: false,
  isLoading: false,
};

initialState.isLogged = !! localStorage.getItem('isLogged');

export default (
  state: AuthState = initialState,
  action: AuthActionTypes,
): AuthState => {
  switch (action.type) {
    case AUTH_LOADING:
      return updateObject<AuthState>(state, {
        isLoading: true
      });

    case LOGIN_SUCCESS:
      localStorage.setItem('isLogged', 'true');

      return updateObject<AuthState>(state, {
        user: action.user,
        isLoading: false,
        isLogged: true,
      });

    case LOGIN_FAILED:
      localStorage.removeItem('isLogged');

      return updateObject<AuthState>(state, {
        user: null,
        isLoading: false,
        isLogged: false,
      });

    case AUTH_STATE_UPDATED:
      action.isLogged ? localStorage.setItem('isLogged', 'true') : localStorage.removeItem('isLogged');

      return updateObject<AuthState>(state, { isLogged: action.isLogged, user: action.user });

    case LOGOUT_SUCCESS:
      localStorage.removeItem('isLogged');

      return updateObject<AuthState>(state, {});

    default:
      return state
  }
}
