import {
  ADD_MESSAGE,
  AlertActionsTypes,
  CLEAR_ALL_MESSAGES,
  CLEAR_MESSAGE,
  AlertState, AppMessage,
} from './types'

const initialState: AlertState = {
  messages: [],
};

export default (
  state: AlertState = initialState,
  action: AlertActionsTypes,
): AlertState => {
  switch (action.type) {
    case ADD_MESSAGE:

      return { ...state, messages: [...state.messages, action.message] };

    case CLEAR_MESSAGE:
      const messages = state.messages.filter((alert: AppMessage) => alert.uuid !== action.uuid);
      return { ...state, messages };

    case CLEAR_ALL_MESSAGES:
      return { ...state };

    default:
      return state
  }
}
