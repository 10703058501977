import axios from 'axios'

const BASE_URL : string = process.env.REACT_APP_FIREBASE_CLOUD_API as string;

export type HttpRequestHeaders = {
    Authorization: string
}

export function httpRequest(headers: HttpRequestHeaders) {
    return axios.create({
        baseURL: BASE_URL,
        headers
    })
}