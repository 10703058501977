import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { SearchInput } from '../../../../components';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const SchoolCreationRequestSearchInput = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    function handleInputChange(event) {
        props.onChange(event.target.value);
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <SearchInput
                    className={classes.searchInput}
                    placeholder="Recherche à partir du nom de l'école..."
                    onChange={handleInputChange}
                />
            </div>
        </div>
    );
};

SchoolCreationRequestSearchInput.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default SchoolCreationRequestSearchInput;
