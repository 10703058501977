import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link} from "react-router-dom";
import { Button } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import {ThemeType} from "../../theme/types";

const useStyles = makeStyles((theme: ThemeType) => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    button: {
        color: "white"
    }
}));

type OwnProps = {
    to :string
};

const Backlink : React.FC<OwnProps> = (props) => {
    const {  to } = props;

    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root)}
        >
            <div className={classes.row}>
                <Link to={to}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<ArrowBackIcon/>}
                        disableElevation
                    >
                        <div className={classes.button}>
                            Retour
                        </div>
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default Backlink;
