export const ADD_MESSAGE = 'alerts/addMessage';
export const CLEAR_MESSAGE = 'alerts/clearMessage';
export const CLEAR_ALL_MESSAGES = 'alerts/clearAllMessages';

export type MessageLevel =  'error' | 'info' | 'warning' | 'success'

export type AppMessage = {
  shortMessage: string
  error?: Error | null
  uuid: string
  level: MessageLevel
}

export type AlertState = {
  messages: AppMessage[]
}

interface AddMessage {
  type: typeof ADD_MESSAGE
  message: AppMessage
}

interface ClearMessage {
  type: typeof CLEAR_MESSAGE
  uuid: string
}

interface ClearAllMessage {
  type: typeof CLEAR_ALL_MESSAGES
}

export type AlertActionsTypes = AddMessage | ClearAllMessage | ClearMessage
