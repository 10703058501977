import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ThemeType } from "../../theme/types";
import { useTitle } from "../../hooks";
import {SchoolCreationRequest } from "../../models/SchoolCreationRequest";
import { ConnectedProps, connect } from "react-redux";
import { flashMessage } from "../../store/modules/alerts/actions";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
    SchoolCreationRequestBackLink as BackLink,
    SchoolCreationRequestValidationForm as ValidationForm,
    SchoolCreationRequestDetails as RequestDetails
} from "./components";
import { Typography, Grid } from "@material-ui/core";
import { StaticContext } from "react-router";

const useStyles = makeStyles((theme: ThemeType) => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    title: {
        color: "#031b4e"
    },
    cardsContainer: {
        paddingTop: theme.spacing(2)
    }
}));

const mapDispatchToProps = { flashMessage };
const connector = connect(null, mapDispatchToProps);

type QueryParams = { id: string };
type LocationParams = { schoolCreationRequest : SchoolCreationRequest};
type ComponentProps = ConnectedProps<typeof connector> & RouteComponentProps<QueryParams, StaticContext, LocationParams>;

const SingleSchoolCreationRequest : React.FC<ComponentProps> = (props) => {
    const { id } = props.match.params;
    const { schoolCreationRequest } = props.location.state;
    const classes = useStyles();

    useTitle(`Requête n° ${id}`);

    return (
        <div className={classes.root}>
            <BackLink to="/school-creation-requests" />
            {
                schoolCreationRequest &&
                <div className={classes.content}>
                    <Typography variant="h1" component="h1" className={classes.title}>
                        { schoolCreationRequest && schoolCreationRequest.name }
                    </Typography>
                    <Grid container spacing={2} className={classes.cardsContainer} >
                        <Grid item xs={6}>
                            <RequestDetails request={schoolCreationRequest} />
                        </Grid>
                        <Grid item xs={6}>
                            <ValidationForm request={schoolCreationRequest} />
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    );
};

export default connector(withRouter(SingleSchoolCreationRequest));