import React, {useEffect, useState} from "react";
import { Typography } from "@material-ui/core";
import { School as SchoolIcon} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { SchoolsTable } from "./components/SchoolsTable";
import {School, SchoolInterface} from "../../models/School";
import {useTitle} from "../../hooks";

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const SchoolList = () => {
    const classes = useStyle();
    const [schools, setSchools] = useState<SchoolInterface[]>([]);

    useTitle("Liste d'écoles ");
    useEffect(() => {
        const unsubscribe = School.getCollection().onSnapshot(querySnapShot => {
            const schools = querySnapShot.docs.map(school => {
                return { id: school.id, ...school.data() } as SchoolInterface;
            });
            setSchools(schools);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className={classes.root}>
            <Typography variant="h2" component="h1">
                <SchoolIcon/> Liste d'écoles
            </Typography>
            <div className={classes.content}>
                <SchoolsTable schools={schools}/>
            </div>
        </div>
    )
};

export default SchoolList;