function updateObject<T = {}>(oldObject: T, newValue: Partial<T>): T {
  return Object.assign({}, oldObject, newValue)
}

function updateItemInArray<T extends Partial<{ id: string | number }>>(
  items: T[],
  itemId: string | number,
  callback: (item: T) => T,
): T[] {
  const updatedItems = items.map((item: T) => {
    if (item.id !== itemId) return item

    const updatedItem = callback(item)
    return updatedItem
  })

  return updatedItems
}

export { updateObject, updateItemInArray }
