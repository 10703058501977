import React from "react";
import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {ThemeType} from "../../../../theme/types";
import {
    SchoolCreationRequest,
} from "../../../../models/SchoolCreationRequest";
import {localeStringDate} from "../../../../utilities"
import StatusChip from "../Utilities/StatusChip";


const useStyles = makeStyles((theme: ThemeType) => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        padding: 0,
        "& .MuiCardContent-root:last-child": {
            paddingBottom: 0
        }
    },
    title: {
        color: "#031b4e",
        fontWeight: "bold"
    },
}));

type OwnProps = {
    request: SchoolCreationRequest
}

const SchoolCreationRequestDetails : React.FC<OwnProps> = (props) => {
    const classes = useStyles();
    const { request } = props;

    return (
        <div>
            <Card>
                <CardContent className={classes.content}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Ecole
                                </TableCell>
                                <TableCell>
                                    { request.name }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Numéro de téléphone
                                </TableCell>
                                <TableCell>
                                    { request.phoneNumber }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Email
                                </TableCell>
                                <TableCell>
                                   { request.email }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Nom du superviseur
                                </TableCell>
                                <TableCell>
                                    { request.supervisorName }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Email du superviseur
                                </TableCell>
                                <TableCell>
                                    { request.supervisorEmail }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Numéro d'autorisation
                                </TableCell>
                                <TableCell>
                                    { request.authorizationRegistrationNumber }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Ville
                                </TableCell>
                                <TableCell>
                                    { request.city }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Statut
                                </TableCell>
                                <TableCell>
                                    { <StatusChip request={request}/> }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Date de creation
                                </TableCell>
                                <TableCell>
                                    { localeStringDate(request.createdAt as { seconds: number} ).toLocaleString() }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    )
};

export default SchoolCreationRequestDetails;