import React from "react";
import {Snackbar, SnackbarOrigin} from "@material-ui/core";
import { AppState } from "../../store/rootReducer";
import { connect, ConnectedProps } from "react-redux";
import {Alert as AlertComponent, Color} from "@material-ui/lab";
import { AppMessage } from "../../store/modules/alerts/types";
import { clearMessage } from "../../store/modules/alerts/actions";

const mapStateToProps = (state: AppState) => ({ alerts: state.alerts});
const mapDispatchToProps = { clearMessage };
const connector = connect(mapStateToProps, mapDispatchToProps);

type AlertProps = ConnectedProps<typeof connector> & {};

const Alert : React.FC<AlertProps> = (props) => {
    const { alerts } = props;

    const position : SnackbarOrigin = {
        vertical: 'bottom',
        horizontal: 'right'
    };

    function handleClose(alert: AppMessage) {
        props.clearMessage(alert.uuid);
    }

    function getSeverity(alert: AppMessage) : Color  {
        return alert.level;
    }

    function renderAlerts() {
        return alerts.messages.map((alert: AppMessage) =>(
            <Snackbar
                open={true}
                autoHideDuration={6000}
                onClose={() => handleClose(alert)}
                anchorOrigin={position}
                key={alert.uuid}
            >
                <AlertComponent onClose={() => handleClose(alert)} severity={getSeverity(alert)}>
                    { alert.shortMessage }
                </AlertComponent>
            </Snackbar>
        ))
    }

    return (
        <>
            { renderAlerts() }
        </>

    )
};

export default connector(Alert);