import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import { useAuthenticate } from "./hooks";
import { Alert } from "./components/Alert";

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

function App() {

    useAuthenticate();

    return (
        <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
                <Routes />
            </Router>
            <Alert/>
        </ThemeProvider>
);
}

export default App;