import { applyMiddleware, createStore, StoreEnhancer, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './rootReducer'

export default function configureStore(): Store {
  const middleware = [thunkMiddleware];
  const middlewareEnhancer: StoreEnhancer = applyMiddleware(...middleware);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers: StoreEnhancer = composeWithDevTools(...enhancers);

  const preloadedState = {};

  const store: Store = createStore(
      rootReducer(),
      preloadedState,
      composedEnhancers,
  );

  // Webpack HMR
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // eslint-disable-next-line no-unused-expressions
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer))
  }

  return store
}
