const AUTH_NOT_FOUND : string = "auth/user-not-found";

type ErrorContent = {
    shortMessage: string,
    message: string
}

type ErrorMapper = {
    [key: string]: ErrorContent
};


export const errorCodeMapper : ErrorMapper = {
    [AUTH_NOT_FOUND]: {
        shortMessage: "Il n'y a pas d'utilisateur qui correspond à vos identifiants",
        message: "There is no existing user record corresponding to the provided identifier.",
    },
};

export function getErrorMessage(key: string) : ErrorContent {

    if(! errorCodeMapper[key]) {
        return {
            shortMessage: "",
            message: ""
        };
    }

    return errorCodeMapper[key];
}