import { HttpRequestHeaders, httpRequest } from "../services/firebase/api";

export interface AuthenticableInterface {
    email: string
    password: string
    displayName: string
    profileId: string
    schoolIds: string[],
    type: "AROLI" | "SCHOOL"
}

export class Auth {
    static create(attributes: AuthenticableInterface, headers: HttpRequestHeaders) {
        return httpRequest(headers).post('users', attributes);
    }
}