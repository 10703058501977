import React from "react";
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Table, TableBody, TableCell, TableRow, CardHeader, Divider, Typography } from "@material-ui/core";
import { ThemeType } from "../../../../theme/types";
import { localeStringDate } from "../../../../utilities";
import { School as SchoolIcon } from "@material-ui/icons";
import { SchoolInterface } from "../../../../models/School";

const useStyles = makeStyles((theme: ThemeType) => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        padding: 0,
        "& .MuiCardContent-root:last-child": {
            paddingBottom: 0
        }
    },
    title: {
        color: "#031b4e",
        fontWeight: "bold"
    },
}));

type OwnProps = {
    school: SchoolInterface
}

const SchoolDetailsCard : React.FC<OwnProps> = (props) => {
    const classes = useStyles();
    const { school } = props;

    return (
        <div>
            <Card>
                <CardHeader
                    avatar={<SchoolIcon/>}
                    title={
                        <Typography variant="h4">Informations sur l'école</Typography>
                    }
                />
                <Divider/>
                <CardContent className={classes.content}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Ecole
                                </TableCell>
                                <TableCell>
                                    { school.name }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Numéro de téléphone
                                </TableCell>
                                <TableCell>
                                    { school.phoneNumber }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Email
                                </TableCell>
                                <TableCell>
                                   { school.email }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Nom du superviseur
                                </TableCell>
                                <TableCell>
                                    { school.supervisorName }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Email du superviseur
                                </TableCell>
                                <TableCell>
                                    { school.supervisorEmail }
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.title}>
                                    Nombre de SMS
                                </TableCell>
                                <TableCell>
                                    { school.schoolSMSCount }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Adresse
                                </TableCell>
                                <TableCell>
                                    { school.address }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.title}>
                                    Date de creation
                                </TableCell>
                                <TableCell>
                                    { localeStringDate(school.createdAt as { seconds: number} ).toLocaleString() }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    )
};

export default SchoolDetailsCard;