import {db} from "../services/firebase/init";
import {BaseModel} from "./BaseModel";

interface UserInterface extends BaseModel {
    schoolIds: string[]
    profileId: string
}


export class User {

    static create() {
    }

    static fetchBy(attributes: { key: string, value: string}) {
        return db.collection('users')
            .where(attributes.key, "==", attributes.value)
            .limit(1)
            .get();
    }

}