import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  AuthActionTypes,
  Credentials,
  User,
  AUTH_STATE_UPDATED, LOGOUT_SUCCESS, AUTH_LOADING,
} from './types'
import { Dispatch } from 'redux'
import { auth } from "../../../services";
import { addMessage } from "../alerts/actions";
import { v4 as uuid } from "uuid";
import { getErrorMessage } from "../../../utilities";


const userIsLoading = () => {
  return {
    type: AUTH_LOADING
  }
};

export const loginSuccess = (user: User): AuthActionTypes => {
  return {
    type: LOGIN_SUCCESS,
    user,
    isLogged: true,
    isLoading: false,
  }
};

export const loginFailed = (): AuthActionTypes => {
  return {
    type: LOGIN_FAILED,
    isLogged: false,
    isLoading: false,
    user: null,
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const login = (credentials: Credentials) => {
  return (dispatch: Dispatch) => {
    dispatch(userIsLoading());

    auth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((response : firebase.auth.UserCredential) => {

        dispatch(loginSuccess(response.user))

      })
      .catch((error) => {

        const { shortMessage } =  getErrorMessage(error.code);

        dispatch(loginFailed());
        dispatch(addMessage({
          shortMessage,
          error,
          uuid: uuid(),
          level: 'error'
        }));

      })
  }
};

export const logout = () => {
  return (dispatch: Dispatch) => {
    auth.signOut()
      .then(() => {
        dispatch(logoutSuccess());
      })
      .catch(() => {
        dispatch(logoutFailed());
      })
  }
};

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
};

const logoutFailed = () => {
  return {
    type: LOGIN_FAILED
  }
};

export const authStateUpdated = (user : firebase.User | null ): AuthActionTypes => ({
  type: AUTH_STATE_UPDATED,
  isLogged: user !== null,
  user
});
