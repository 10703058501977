import firebase from 'firebase/app'

export type User = Readonly<firebase.User | null>

export type AuthState = {
  user: User
  isLogged: boolean | null
  isLoading: boolean | null
}

export type Credentials = {
  email: string
  password: string
}

export const LOGIN = 'auth/login';
export const LOGOUT = 'auth/logout';
export const LOGOUT_SUCCESS = 'auth/logoutSuccess';
export const LOGOUT_FAILED = 'auth/logoutFailed';
export const LOGIN_SUCCESS = 'auth/loginSuccess';
export const LOGIN_FAILED = 'auth/loginFailed';
export const AUTH_STATE_UPDATED = 'auth/authStateUpdated';
export const AUTH_LOADING = 'auth/loading';

interface Login {
  type: typeof LOGIN
}

interface AuthStateUpdated {
  type: typeof AUTH_STATE_UPDATED
  isLogged: boolean,
  user: firebase.User | null
}

interface LoginFailed {
  type: typeof LOGIN_FAILED
  user: User | null
  isLogged: boolean
  isLoading: boolean
}

interface LoginSuccess {
  type: typeof LOGIN_SUCCESS
  user: User | null
  isLogged: boolean
  isLoading: boolean
}

interface Logout {
  type: typeof LOGOUT
  isLoading: boolean
}

interface LogoutSuccess {
  type: typeof LOGOUT_SUCCESS
  isLogged: boolean
  isLoading: boolean
}

interface Loading {
  type: typeof AUTH_LOADING
}

export type AuthActionTypes =
  | Login
  | LoginFailed
  | LoginSuccess
  | Logout
  | LogoutSuccess
  | AuthStateUpdated
  | Loading
