import React from "react";
import {SchoolInterface} from "../../../../models/School";
import { makeStyles } from "@material-ui/styles";
import { ThemeType } from "../../../../theme/types";
import clsx from 'clsx';
import { localeStringDate } from "../../../../utilities/index";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import {
    Card, CardActions, CardContent,
    Table,
    TableBody, TableCell,
    TableHead, TableRow,
    Typography, TablePagination, Button
} from '@material-ui/core';
import { useButtonStyles } from "../../../../style";

type OwnProps = {
    schools: SchoolInterface[]
}

const useStyles = makeStyles((theme: ThemeType) => ({
    root: {
        marginTop: theme.spacing(3)
    },
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end',
    }
}));

const SchoolsTable : React.FC<OwnProps> = (props) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const {schools} = props;

    return (
        <div>
            <Card
                className={clsx(classes.root)}
            >
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Nom du superviseur</TableCell>
                                        <TableCell>Téléphone du demandeur</TableCell>
                                        <TableCell>Nombre de SMS</TableCell>
                                        <TableCell>Date de création</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { schools.map((school: SchoolInterface) => (
                                        <TableRow
                                            className={""}
                                            hover
                                            key={school.id}
                                        >
                                            <TableCell>
                                                <div className={classes.nameContainer}>
                                                    <Typography variant="body1">{school.name}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>{school.supervisorName}</TableCell>
                                            <TableCell>
                                                {school.phoneNumber}
                                            </TableCell>
                                            <TableCell>{ school.schoolSMSCount } SMS</TableCell>
                                            <TableCell>
                                                { localeStringDate(school.createdAt as { seconds : number}).toLocaleString() }
                                            </TableCell>
                                            <TableCell>

                                                <Link to={{
                                                    pathname: `/schools/${school.id}`,
                                                    state: { school }
                                                }}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        disableElevation
                                                        className={buttonClasses.actionButton}
                                                    >
                                                        <div className="">
                                                            Details
                                                        </div>
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </PerfectScrollbar>
                </CardContent>
                {/*<CardActions className={classes.actions}>*/}
                {/*  <TablePagination*/}
                {/*    component="div"*/}
                {/*    count={users.length}*/}
                {/*    onChangePage={handlePageChange}*/}
                {/*    onChangeRowsPerPage={handleRowsPerPageChange}*/}
                {/*    page={page}*/}
                {/*    rowsPerPage={rowsPerPage}*/}
                {/*    rowsPerPageOptions={[5, 10, 25]}*/}
                {/*  />*/}
                {/*</CardActions>*/}
            </Card>
        </div>
    )
};

export default SchoolsTable;