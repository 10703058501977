import {
  ADD_MESSAGE,
  CLEAR_ALL_MESSAGES,
  CLEAR_MESSAGE,
  AlertActionsTypes,
  AppMessage,
} from './types'
import { v4 as uuid} from "uuid";
import {Dispatch} from "redux";

export const addMessage = (message: AppMessage): AlertActionsTypes => {
  message.uuid = message.uuid === "" ? message.uuid : uuid();

  return {
    type: ADD_MESSAGE,
    message,
  }
};

export const clearMessage = (uuid: string): AlertActionsTypes => ({
  type: CLEAR_MESSAGE,
  uuid,
});

export const clearAllMessages = (): AlertActionsTypes => ({
  type: CLEAR_ALL_MESSAGES,
});

export const flashMessage = (message: AppMessage) => {
  return (dispatch: Dispatch) => {
    dispatch(addMessage(message))
  }
};