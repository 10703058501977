import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { localeStringDate } from "../../../../utilities/index";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Link } from "react-router-dom";
import {
    Card, CardActions, CardContent,
     Table,
    TableBody, TableCell,
    TableHead, TableRow,
    Typography, TablePagination, Button
} from '@material-ui/core';
import {
    ArrowForward as ArrowForwardIcon,
    DoneAll as DoneAllIcon,
    HighlightOff as HighlightOffIcon,
    Timelapse as TimelapseIcon
} from '@material-ui/icons';
import StatusChip from "../Utilities/StatusChip";


const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end',
    },
    actionButton: {
        borderRadius: "100px",
        textTransform: "Capitalize"
    }
}));

const SchoolCreationRequestsTable = props => {
    const {className, data, ...rest} = props;

    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const getStatusIcon = (status) => {
        if(status === "pending") {
            return <TimelapseIcon color="secondary"/>
        }

        if(status === "rejected") {
            return <HighlightOffIcon color="error"/>
        }

        return <DoneAllIcon color="primary"/>
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ecole</TableCell>
                                    <TableCell>Nom du demandeur</TableCell>
                                    <TableCell>Téléphone du demandeur</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date de création</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(0, rowsPerPage).map(schoolCreationRequest => (
                                    <TableRow
                                        className={classes.tableRow}
                                        hover
                                        key={schoolCreationRequest.id}
                                    >
                                        <TableCell>
                                            <div className={classes.nameContainer}>
                                                <Typography variant="body1">{schoolCreationRequest.name}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell>{schoolCreationRequest.supervisorName}</TableCell>
                                        <TableCell>
                                            {schoolCreationRequest.phoneNumber}
                                        </TableCell>
                                        <TableCell>{<StatusChip request={schoolCreationRequest}/>}</TableCell>
                                        <TableCell>
                                            { localeStringDate(schoolCreationRequest.createdAt).toLocaleString() }
                                        </TableCell>
                                        <TableCell>
                                            <Link to={{
                                                pathname: `/school-creation-requests/${schoolCreationRequest.id}`,
                                                state: {
                                                    schoolCreationRequest: schoolCreationRequest
                                                }
                                            }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.actionButton}
                                                    disableElevation
                                                >
                                                    <div className="">
                                                        Details
                                                    </div>
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            {/*<CardActions className={classes.actions}>*/}
            {/*  <TablePagination*/}
            {/*    component="div"*/}
            {/*    count={users.length}*/}
            {/*    onChangePage={handlePageChange}*/}
            {/*    onChangeRowsPerPage={handleRowsPerPageChange}*/}
            {/*    page={page}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    rowsPerPageOptions={[5, 10, 25]}*/}
            {/*  />*/}
            {/*</CardActions>*/}
        </Card>
    );
};

SchoolCreationRequestsTable.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired
};

export default SchoolCreationRequestsTable;
