import React  from 'react';
import { Link as RouterLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid, Button, Link, Typography, CircularProgress } from '@material-ui/core';
import { Formik, FormikProps, FormikValues, Field } from "formik";
import { TextField } from 'formik-material-ui';
import useStyles from "./style";
import useTitle from "../../hooks/useTitle";
import * as Yup from "yup";
import { login } from "../../store/modules/auth/actions";
import { AuthState, Credentials } from "../../store/modules/auth/types";
import { connect } from "react-redux";
import { AppState } from "../../store/rootReducer";

type InputType = { email: string; password: string }
type SignInProps = DispatchProps & RouteComponentProps;
interface DispatchProps {
  login : (credentials: Credentials) => void
  auth: AuthState
}

const SignUpValidationSchema = Yup.object().shape({
  email: Yup.string()
            .required("Le champ e-mail est requis")
            .email("Veuillez entrer une adresse e-mail correcte"),
  password: Yup.string()
               .required("Le champ mot de passe est requis")
});

const SignIn : React.FC<SignInProps> = (props) => {
  const { auth } = props;
  const classes = useStyles();
  const inputs : InputType = { email: "", password: "" };

  useTitle("Login");

  function handleSubmit(values : InputType) {
    props.login(values);
  }

  function isDisabled(props: FormikProps<FormikValues>) : boolean {
    return Object.values(props.errors).length > 0;
  }

  function isLoading() {
    return auth.isLoading;
  }

  function renderSubmitButtonText(){
    if(isLoading()){
      return <CircularProgress className={classes.loader} size={25}/>
    }
    return "Connexion";
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <div>
                <Typography
                  className={classes.name}
                  variant="body1"
                >
                  <img src="/images/logos/logo-arolitec-white.png" alt="Logo arolitec"
                    width={300}
                  />
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <Formik initialValues={inputs} onSubmit={handleSubmit}
                validationSchema={SignUpValidationSchema}
              >
                { (props: FormikProps<FormikValues>) => (
                    <form
                        className={classes.form}
                        onSubmit={props.handleSubmit}
                    >
                      <Typography
                          className={classes.title} variant="h2"
                      >
                        Akylix administration
                      </Typography>
                      <Grid
                          className={classes.socialButtons}
                          container
                          spacing={2}
                      >
                      </Grid>
                      <Field
                        component={TextField}
                        className={classes.textField}
                        label="Adresse e-mail"
                        name="email"
                        variant="outlined"
                        disabled={isLoading()}
                        fullWidth
                      />
                      <Field
                          component={TextField}
                          className={classes.textField}
                          label="Mot de passe"
                          name="password"
                          variant="outlined"
                          type="password"
                          disabled={isLoading()}
                          fullWidth
                      />
                      <Button
                          className={classes.signInButton}
                          color="primary"
                          disabled={isDisabled(props)}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                      >
                        { renderSubmitButtonText() }
                      </Button>
                      <Typography
                          color="textSecondary"
                          variant="body1"
                          className={classes.forgotPassword}
                      >
                        <Link
                            component={RouterLink}
                            to="#"
                            variant="h6"
                        >
                          Mot de passe oublié ?
                        </Link>
                      </Typography>
                    </form>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps  = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
