import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Dispatch } from 'redux'
import { auth } from '../services'
import { authStateUpdated } from '../store/modules/auth/actions'

export default function useAuthenticate(): void {
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user: firebase.User | null) => {
      dispatch(authStateUpdated(user))
    });

    return () => unsubscribe();
  })
}
