import {
  combineReducers,
  ReducersMapObject,
  Action,
  CombinedState,
  Reducer,
} from 'redux'
import authReducer from './modules/auth/reducer'
import alertsReducer from './modules/alerts/reducer'
import { AuthState } from './modules/auth/types'
import { AlertState } from './modules/alerts/types'

export type AppState = {
  auth: AuthState
  alerts: AlertState
}

const rootReducer = (): Reducer<CombinedState<AppState>, Action<any>> => {
  const reducersMap: ReducersMapObject<AppState> = {
    auth: authReducer,
    alerts: alertsReducer,
  }

  return combineReducers(reducersMap)
}

export default rootReducer
