import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link} from "react-router-dom";
import { Button } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  button: {
    color: "white"
  }
}));

const SchoolCreationRequestBackLink = props => {
  const { className, to, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Link to={to}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<ArrowBackIcon/>}
            disableElevation
          >
              <div className={classes.button}>
                Retour
              </div>
          </Button>
        </Link>
      </div>
    </div>
  );
};

SchoolCreationRequestBackLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired
};

export default SchoolCreationRequestBackLink;
