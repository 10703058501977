import {BaseModel} from "./BaseModel";
import { db } from "../services/firebase/init";
import firebase from 'firebase/app'

export interface SchoolInterface extends BaseModel {
    name: string
    address?: string
    phoneNumber: Array<string>
    email: Array<string>
    studentSMSCount: number
    schoolSMSCount: number
    supervisorName: string
    supervisorEmail: string

    slogan?: string
    schoolCode: string
    city: string
    country?: string
    logoRef?: string
    location?: firebase.firestore.GeoPoint

    chairman?: string
    chairmanPhones: string[]
    creationDate?: number

    token: string | null
}


export class School {
    private static collectionName: string = "schools";
    private readonly attributes : SchoolInterface;

    constructor(attributes: SchoolInterface) {
       this.attributes = attributes;
    }

    static fetchAll() {
        return db.collection(School.collectionName).get();
    }

    static create(attributes : SchoolInterface) {
        return db.collection(School.collectionName).add(attributes);
    }

    static getCollection() {
        return db.collection(School.collectionName);
    }

    update(attributes:object) {
        const { id } = this.attributes;

        return db.collection(School.collectionName)
                .doc(id)
                .set({...this.attributes, ...attributes});
    }
}
