import { db } from "../services/firebase/init";

const SUPERVISOR_PROFILE_NAME_NAME = "Superviseur";

export const AROLI_PROFILE_NAME_TYPE = "AROLI";
export const SCHOOL_PROFILE_NAME_TYPE = "SCHOOL";

export const ADMINISTRATOR_PROFILE_NAME = "Administrateur";
export const CHECKOUT_PROFILE_NAME = "Caissière";
export const TEACHER_PROFILE_NAME = "Enseignant";
export const ADMINISTRATION_PROFILE_NAME = "Administration";

export type ProfileName = typeof ADMINISTRATOR_PROFILE_NAME |
    typeof CHECKOUT_PROFILE_NAME |
    typeof TEACHER_PROFILE_NAME |
    typeof ADMINISTRATION_PROFILE_NAME;

export interface ProfileInterface {
    name: ProfileName,
    schoolId: string | null
    type: typeof AROLI_PROFILE_NAME_TYPE | typeof SCHOOL_PROFILE_NAME_TYPE,
    createdAt: Date,
    updatedAt: Date
}

export const defaultProfileNames : ProfileName[] = [
    ADMINISTRATOR_PROFILE_NAME,
    CHECKOUT_PROFILE_NAME,
    TEACHER_PROFILE_NAME,
    ADMINISTRATION_PROFILE_NAME
];

export class Profile {
    static getSupervisor() {
        return db.collection('profiles')
                .where('name', '==', SUPERVISOR_PROFILE_NAME_NAME)
                .limit(1)
                .get();
    }

    static create(attributes: ProfileInterface ) {
        return db.collection('profiles').add(attributes);
    }
}

